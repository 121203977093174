$(function () {

	var $inventoryNav = $('.inventory-nav');
  var $supplyNav = $('.supply-nav');
  var $inventoryContainer = $('.inventory-tab-container');
  var $supplyContainer = $('.supply-tab-container');

  $inventoryNav.on('click', function() {
    if ($inventoryNav.hasClass('active-inventory-nav')) {
      return
    } else {
      // Change Nav Highlight
        $inventoryNav.removeClass('inactive-inventroy-nav')
        $inventoryNav.addClass('active-inventory-nav');
        $supplyNav.removeClass('active-inventory-nav');
        $supplyNav.addClass('inactive-inventory-nav')
      
      // Show Inventory Section
        $inventoryContainer.removeClass("hidden");

      // Hide Supplies
        $supplyContainer.addClass("hidden");
    }
  })

  $supplyNav.on('click', function() {
    if ($supplyNav.hasClass('active-inventory-nav')) {
      return
    } else {
      // Change Nav Highlight
        $supplyNav.removeClass('inactive-inventroy-nav')
        $supplyNav.addClass('active-inventory-nav');
        $inventoryNav.removeClass('active-inventory-nav');
        $inventoryNav.addClass('inactive-inventory-nav')
      
      // Show Supplies Section
        $supplyContainer.removeClass("hidden");

      // Hide Inventory
        $inventoryContainer.addClass("hidden");
    }
  })

})