// TODO: CREATE FUNCTION TO CALCULATE PRICE
// TODO: DISPLAY QUANTITY TO CURRENT ORDER VIEW AND INCREMENT IT FOR ADDITIONAL CLICKS FROM LEFT SIDE AND FROM CURRENT ORDER SIDE
$(function () {
	var $mainTaskCard = $('.main-task-card');
	var $studentTaskCard = $('.student-task-card');
	var $assignTaskButton = $('.assign-task-button');

	var businessName = document.querySelector("#business-name");
	var $editBizNav = $('#edit-biz-nav');
	var $addDocNav = $('#add-doc-nav');
	var $shareBizNav = $('#share-biz-nav');
	var $dangerZoneNav = $('#danger-zone-nav');
	var $editBizContainer = $('#edit-biz-container');
	var $addDocContainer = $('#add-doc-container');
	var $shareBizContainer = $('#share-biz-container');
	var $dangerZoneContainer = $('#danger-zone-container');

	var $swapBusiness = $(".swap-business");
	var $swapBusinessContainer = $(".swap-business-container");
	var $posProduct = $(".pos-product");
	var $currentOrderContainer = $(".current-order-container");
	var $posCreateOrderButton = $(".pos-create-order-button");
	var $paymentFinalizeOrderButton = $(".payment-finalize");
	var $posCustomerNameInputContainer = $(".customer-name-input");
	var $paymentExitButton = $(".payment-exit");
	var $paymentModalContainer = $(".payment-modal-container");
	var posCustomerInputField = document.querySelector(".pos-input");
	var cashReceivedField =  document.querySelector(".cash-received");
	var $cashReceivedContainer = $(".cash-received-container")
	var paymentDifference = document.querySelector(".payment-difference");
	var $posProduct = $(".pos-product");
	var $removePosProduct = $(".remove-pos-product");
	var $cashPayButton = $(".cash-payment");
	var $creditPayButton = $(".credit-payment");
	var posCurrentOrders = []
	var selectedTasks = []
	var selectedStudents = []
	var $editAuthSub = $("#edit-auth-submit");
	var $editAuthModal = $("#edit-auth-modal");
	var $editAuthError = $("#edit-auth-error");
	var businessDestroyInput = document.querySelector("#business_destroy_target");
	var $businessRemoveDisabled = $("#biz-remove-disabled");
	var $businessRemoveActive =  $("#biz-remove-active");


	const cardBlocks = document.querySelectorAll(".cards");

	cardBlocks.forEach((cardBlock) => {

	  cardBlock.style.paddingBottom = `${Math.max(cardBlock.children.length, 1)}em`;

	  Array.from(cardBlock.children).forEach(
	    (card, i) => (card.style.transform = `translateY(${i}em)`)
	  );
	});

	jQuery(document).ready(function($){
	  $('ul.cards').on('click', function(e){
	  	console.log(e.target);
	  	if (e.target.classList.contains('card-link')){
	  		console.log($(this));
	  		console.log("target is a link");
	    	return;
	  	}
	    $(this).toggleClass('transition');
	  });

	  $('ul.cards-split').on('click', function(e){
	  	var $docContainer = $(".doc-container");

	  	console.log(e.target);
	  	if (e.target.classList.contains('card-link')){
	  		console.log($(this));
	  		console.log("target is a link");
	    	return;
	  	}

	    $(this).toggleClass('transition');
	    $docContainer.toggleClass('doc-grow');
	  });
	});


	$swapBusiness.on('click', function () {
		console.log('clicked on swap business');
		if ($swapBusinessContainer.hasClass('hidden')) {
			$swapBusinessContainer.removeClass('hidden');	
		} else {
			$swapBusinessContainer.addClass('hidden');	
		}
		
	})

	// Edit Business Side Nav

	$editBizNav.on('click', function() {
		if ($editBizNav.hasClass('active-side-nav')) {
			return
		} else {
			$editBizNav.addClass('active-side-nav').removeClass('inactive-side-nav');
			$addDocNav.removeClass('active-side-nav').addClass('inactive-side-nav');
			$shareBizNav.removeClass('active-side-nav').addClass('inactive-side-nav');
			$dangerZoneNav.removeClass('active-side-nav').addClass('inactive-side-nav');

			// Show Edit biz
			$editBizContainer.removeClass('lg:hidden');
			$addDocContainer.addClass('lg:hidden');
			$shareBizContainer.addClass('lg:hidden');
			$dangerZoneContainer.addClass('lg:hidden');
		}
	})

	$addDocNav.on('click', function() {
		if ($addDocNav.hasClass('active-side-nav')) {
			return
		} else {
			$addDocNav.addClass('active-side-nav').removeClass('inactive-side-nav');
			$editBizNav.removeClass('active-side-nav').addClass('inactive-side-nav');
			$shareBizNav.removeClass('active-side-nav').addClass('inactive-side-nav');
			$dangerZoneNav.removeClass('active-side-nav').addClass('inactive-side-nav');

			// Show add doc
			$addDocContainer.removeClass('lg:hidden');
			$editBizContainer.addClass('lg:hidden');
			$shareBizContainer.addClass('lg:hidden');
			$dangerZoneContainer.addClass('lg:hidden');
		}
	})

	$shareBizNav.on('click', function() {
		if ($shareBizNav.hasClass('active-side-nav')) {
			return
		} else {
			$shareBizNav.addClass('active-side-nav').removeClass('inactive-side-nav');
			$addDocNav.removeClass('active-side-nav').addClass('inactive-side-nav');
			$editBizNav.removeClass('active-side-nav').addClass('inactive-side-nav');
			$dangerZoneNav.removeClass('active-side-nav').addClass('inactive-side-nav');

			// Show share biz
			$shareBizContainer.removeClass('lg:hidden');
			$addDocContainer.addClass('lg:hidden');
			$editBizContainer.addClass('lg:hidden');
			$dangerZoneContainer.addClass('lg:hidden');
		}
	})

	$dangerZoneNav.on('click', function() {
		if ($dangerZoneNav.hasClass('active-side-nav')) {
			return
		} else {
			$dangerZoneNav.addClass('active-side-nav').removeClass('inactive-side-nav');
			$addDocNav.removeClass('active-side-nav').addClass('inactive-side-nav');
			$editBizNav.removeClass('active-side-nav').addClass('inactive-side-nav');
			$shareBizNav.removeClass('active-side-nav').addClass('inactive-side-nav');

			// Show share biz
			$dangerZoneContainer.removeClass('lg:hidden');
			$addDocContainer.addClass('lg:hidden');
			$editBizContainer.addClass('lg:hidden');
			$shareBizContainer.addClass('lg:hidden');
		}
	})

	$editAuthSub.on('click', function() {
		console.log("submit auth sent")
		var pass = document.getElementById("edit_auth_input").value;
		console.log(pass)

		$.ajax({
      url: "/edit_auth",
      type: "post",
      dataType: "json",
      data: {
      	pass: pass,
      },
      // headers: {"X-CSRF-TOKEN": $token},
      success: function(response) {
      	$editAuthModal.addClass('hidden');
      },
      error: function(response) {
      	console.log(response);
      	$editAuthError.removeClass('hidden');
      }
    });

	})

	// Make sure they know which business they are trying to delete by making them type the name of it in before unlocking button
	if(businessDestroyInput) {
		businessDestroyInput.addEventListener("keyup", (event) => {

			if(businessDestroyInput.value == businessName.textContent) {
				// Unlock Delete Confirmation Button
				$businessRemoveDisabled.addClass('hidden');
				$businessRemoveActive.removeClass('hidden');
			} else {
				// Lock Delete Confirmation Button
				$businessRemoveDisabled.removeClass('hidden');
				$businessRemoveActive.addClass('hidden');
			}

		});
	}



	// IF YOU CHECK FOR THE CLICK ON THE ORDER CONTAINER, IT SHOULD PICKUP ELEMENTS THAT ARE ADDED LATER
	$currentOrderContainer.on('click', ".remove-pos-product", function() {
		// find the X button's parent and hide it and update calculations
		console.log($(this).parent().data('product-id'));
		var removeTarget = $(this).parent().data('product-id');

		// filter out all instances of the product id from current orders
		posCurrentOrders = posCurrentOrders.filter((productId) => productId !== removeTarget);

		$(this).parent().remove();
		calculateRunningTotal();

		// if current orders is empty hide create order
		if($.trim($currentOrderContainer.html())=='') {
			$posCustomerNameInputContainer.addClass('hidden');
			$posCreateOrderButton.addClass('hidden');
		}
	})
	// END

	// Key up listener for POS customer name to reveal create order if filled and disable if not filled

	if (posCustomerInputField) {
		posCustomerInputField.addEventListener("keyup", (event) => {

			if(posCustomerInputField.value=='') {

				// Disable Create Order Button
				$posCreateOrderButton.addClass('opacity-50 cursor-not-allowed');
				$posCreateOrderButton.removeClass('hover:bg-orange-600 cursor-pointer');
			} else {

				// Enable Create Order Button
				$posCreateOrderButton.removeClass('opacity-50 cursor-not-allowed');
				$posCreateOrderButton.addClass('hover:bg-orange-600 cursor-pointer');
			}
		});
	}

	// Cash Payment Functions 
	if (cashReceivedField) {
		// Key up listener for Cash Payment Entered to format
		// cashReceivedField.addEventListener("keyup", (event) => {
		// 	setTimeout(() => {
		// 		cashReceivedField.value = (Math.round(cashReceivedField.value * 100) / 100).toFixed(2);
		// 	}, 10000)			
		// });

		// Key up listener to check if change is necessary 
		cashReceivedField.addEventListener("keyup", (event) => {
			setTimeout(() => {
				var totalAmountDue = document.querySelector(".total-due").innerHTML;
				if (parseFloat(cashReceivedField.value)> totalAmountDue) {
					// Change Due
					difference = cashReceivedField.value - totalAmountDue;
					paymentDifference.classList.remove("payment-error");
					paymentDifference.classList.add("font-bold");
					paymentDifference.innerHTML = "$" + (Math.round(difference * 100) / 100).toFixed(2);
					$paymentFinalizeOrderButton.removeClass('button-primary-disabled');
					$paymentFinalizeOrderButton.addClass('button-primary');

				} else if (parseFloat(cashReceivedField.value) < totalAmountDue) {
					// Amount received too low
					paymentDifference.classList.remove("font-bold");
					paymentDifference.classList.add("payment-error");
					paymentDifference.innerHTML = "Invalid Payment Amount"
					$paymentFinalizeOrderButton.addClass('button-primary-disabled');
					$paymentFinalizeOrderButton.removeClass('button-primary');
				} else if (parseFloat(cashReceivedField.value) == totalAmountDue) {
					// No Change Due
					paymentDifference.classList.remove("payment-error");
					paymentDifference.classList.add("font-bold");
					paymentDifference.innerHTML = "None";
					$paymentFinalizeOrderButton.removeClass('button-primary-disabled');
					$paymentFinalizeOrderButton.addClass('button-primary');
					
				} else {
					// Empty field
					paymentDifference.innerHTML = "";
					$paymentFinalizeOrderButton.addClass('button-primary-disabled');
					$paymentFinalizeOrderButton.removeClass('button-primary');
				}
			}, 1500)
		});
	}

	$posProduct.on('click', function () {
		var productId = $(this).data('product-id');
		var productName = $(this).data('product-name');
		var productPrice = $(this).data('product-price');
		var businessId = $(this).data('business-id');

		// console.log(productId);
		console.log(productName);
		console.log(productPrice);
		console.log(businessId);
		// CHECKS TO SEE IF CURRENT ORDER CONTAINER IS EMPTY IF SO ADD PRODUCT
		// ELSE IF CONTENT EXISTS IT CHECKS POS CURRENT ORDERS ARRAY HAS PRODUCT ID ALREADY AND GOES TO INCREMENT
		// ELSE ADDS NEW PRODUCT THAT DOESN'T EXIST IN POS CURRENT ORDERS ARRAY TO LIST AND ADDS TO ARRAY
		if($.trim($currentOrderContainer.html())=='') {
			var newProductForCurrentOrder = `
			<div data-business-id=${businessId} data-product-id="${productId}" data-product-price="${productPrice}" data-product-name='${productName}' data-product-quantity=1 
					class="card-body pos-product text-dark text-center text-white bg-green-500 shadow-md rounded p-2 mb-2">
          <h5 class="card-title text-xl font-bold text-white">${productName}</h5>
          <p class="float-right remove-pos-product text-white">X</p> 
          <p class="card-text text-white">Retail Price: $${productPrice}0</p>
          <p class="card-text text-white">Order Quantity: <span class="product-${productId}-quantity">1</span></p>
        </div>`
			$currentOrderContainer.append(newProductForCurrentOrder);

			posCurrentOrders.push(productId);
		} else if(posCurrentOrders.includes(productId)) {
			// INCREMENT PRODUCT UP BY ONE FOR PRODUCT CLICKED
			var productQuantity = parseInt($("."+"product-"+$(this).data('product-id')+"-quantity").text());
			var $quantityTarget = $("."+"product-"+$(this).data('product-id')+"-quantity")
			var increasedQuantity = (productQuantity +=1);

			$quantityTarget.text(increasedQuantity);
		} else {
			// ADD NEW PRODUCT
			var newProductForCurrentOrder = `<div data-business-id=${businessId} data-product-id=${productId} data-product-price=${productPrice} data-product-name=${productName} data-product-quantity=1 
			class="card-body pos-product text-dark text-center text-white bg-green-500 shadow-md rounded p-2 mb-2">
          <h5 class="card-title text-xl font-bold text-white">${productName}</h5>
          <p class="float-right remove-pos-product text-white">X</p> 
          <p class="card-text text-white">Retail Price: $${productPrice}0</p>
          <p class="card-text text-white">Order Quantity: <span class="product-${productId}-quantity">1</span></p>
        </div>`
			$currentOrderContainer.append(newProductForCurrentOrder);

			posCurrentOrders.push(productId);
		}

		// }
		// check for presence END

		$posCustomerNameInputContainer.removeClass('hidden');
		$posCreateOrderButton.removeClass('hidden');
		calculateRunningTotal();
	})

	$posCreateOrderButton.on('click', function() {
		let customerName = $(".pos-input").val();

		if ($posCreateOrderButton.hasClass('cursor-not-allowed')) {
			return false
		} else {
			createArrayOfProducts(customerName);
		}
	})

	$paymentFinalizeOrderButton.on('click',function() {
		let customerName = $(".pos-input").val();

		if ($paymentFinalizeOrderButton.hasClass('cursor-not-allowed')) {
			return false
		} else {
			finalizeProductOrder(customerName);
		}
	});

	function calculateRunningTotal() {
		// loop through and add up all prices
		// update dom element
	}

	function finalizeProductOrder(customerName) {
		var thisCustomerName = customerName
		var immediateOrder = document.querySelector('.immediate-fulfillment').checked
		var orderProducts = []
		$currentOrderContainer.find(".pos-product").each(function () {
			// START WITH HASH
			var productHash = {}
			productHash["product_id"] = $(this).data('product-id');
			productHash["product_price"] = $(this).data('product-price');
			productHash["product_quantity"] = parseInt($("."+"product-"+$(this).data('product-id')+"-quantity").text());
			orderProducts.push(productHash);
			// END WITH HASH
			// orderProducts.push($(this).data('product-id'));
		})

		// This creates an order
		createOrder(orderProducts, thisCustomerName, immediateOrder);
	}

	function createArrayOfProducts(customerName) {
		var thisCustomerName = customerName
		var orderProducts = []
		$currentOrderContainer.find(".pos-product").each(function () {
			// START WITH HASH
			var productHash = {}
			productHash["product_id"] = $(this).data('product-id');
			productHash["product_price"] = $(this).data('product-price');
			productHash["product_quantity"] = parseInt($("."+"product-"+$(this).data('product-id')+"-quantity").text());
			orderProducts.push(productHash);
			// END WITH HASH
			// orderProducts.push($(this).data('product-id'));
		})

		// Calculate amount owed
		calculateTotalOwed(orderProducts, thisCustomerName);

		// This creates an order Need to calculate before
		// createOrder(orderProducts, thisCustomerName);
	}

	$mainTaskCard.on('click', function () {
		if ($(this).hasClass('task-selected')) {
			const index = selectedTasks.indexOf($(this).data('task-id'));
			if (index > -1) { // only splice array when item is found
  			selectedTasks.splice(index, 1); // 2nd parameter means remove one item only
			}
			$(this).removeClass("task-selected");
			$(this).addClass("bg-white");
			$(this).removeClass("bg-green-600");
			$(this).find('h5').removeClass("text-white");
		} else {
			$(this).addClass("task-selected");
			$(this).removeClass("bg-white");
			$(this).addClass("bg-green-600");
			$(this).find('h5').addClass("text-white");
			selectedTasks.push($(this).data('task-id'));	
		}
	})

	$studentTaskCard.on('click', function () {
		if ($(this).hasClass('student-selected')) {
			const index = selectedStudents.indexOf($(this).data('student-id'));
			if (index > -1) { // only splice array when item is found
  			selectedStudents.splice(index, 1); // 2nd parameter means remove one item only
			}
			$(this).removeClass("student-selected");
			$(this).addClass("bg-white");
			$(this).removeClass("bg-yellow-600");
			$(this).find('h5').removeClass("text-white");
		} else {
			$(this).addClass("student-selected");
			$(this).removeClass("bg-white");
			$(this).addClass("bg-yellow-600");
			$(this).find('h5').addClass("text-white");
			selectedStudents.push($(this).data('student-id'));	
		}
	})

	$assignTaskButton.on('click', function () {
		saveTaskAssignments();
	})

	// Activate Cash Pay Option
	$cashPayButton.on('click', function() {
		if ($cashPayButton.hasClass('active-payment-choice')) {
			return
		} else if ($creditPayButton.hasClass('active-payment-choice')){
			$(this).removeClass('payment-method-option').addClass('payment-method-option-selected active-payment-choice');
			$creditPayButton.removeClass('active-payment-choice payment-method-option-selected').addClass('payment-method-option');
			$cashReceivedContainer.removeClass('hidden');
			paymentDifference.innerHTML = "";
			cashReceivedField.value = "0.00";
			$paymentFinalizeOrderButton.addClass('button-primary-disabled');
			$paymentFinalizeOrderButton.removeClass('button-primary');
		} else {
			// Show Cash received input, change style to be selected payment option
			$(this).removeClass('payment-method-option').addClass('payment-method-option-selected active-payment-choice');
			$cashReceivedContainer.removeClass('hidden');
			paymentDifference.innerHTML = "";
			cashReceivedField.value = "0.00";
			$paymentFinalizeOrderButton.addClass('button-primary-disabled');
			$paymentFinalizeOrderButton.removeClass('button-primary');
		}
	})

	// Activate Credit Pay Option
	$creditPayButton.on('click', function() {
		if ($creditPayButton.hasClass('active-payment-choice')) {
			return
		} else if ($cashPayButton.hasClass('active-payment-choice')){
			$(this).removeClass('payment-method-option').addClass('payment-method-option-selected active-payment-choice');
			$cashPayButton.removeClass('active-payment-choice payment-method-option-selected').addClass('payment-method-option');
			$cashReceivedContainer.addClass('hidden');
			paymentDifference.classList.remove("payment-error");
			paymentDifference.classList.add("font-bold");
			paymentDifference.innerHTML = "None";
			$paymentFinalizeOrderButton.removeClass('button-primary-disabled');
			$paymentFinalizeOrderButton.addClass('button-primary');
		} else {
			// Show Credit received input, change style to be selected payment option
			$(this).removeClass('payment-method-option').addClass('payment-method-option-selected active-payment-choice');
			$cashReceivedContainer.addClass('hidden');
			paymentDifference.classList.remove("payment-error");
			paymentDifference.classList.add("font-bold");
			paymentDifference.innerHTML = "None";
			$paymentFinalizeOrderButton.removeClass('button-primary-disabled');
			$paymentFinalizeOrderButton.addClass('button-primary');
		}
	})

	// Close Payment Screen
	$paymentExitButton.on('click', function() {
		$paymentModalContainer.addClass("hidden");
	});


	function urlWithRndQueryParam(url, paramName) {
    const ulrArr = url.split('#');
    const urlQry = ulrArr[0].split('?');
    const usp = new URLSearchParams(urlQry[1] || '');
    usp.set(paramName || '_z', `${Date.now()}`);
    urlQry[1] = usp.toString();
    ulrArr[0] = urlQry.join('?');
    return ulrArr.join('#');
	}

	function handleHardReload(url) {
    window.location.href = urlWithRndQueryParam(url);
    // This is to ensure reload with url's having '#'
    window.location.reload();
	}

	function calculateTotalOwed(orderProducts, customerName) {
		$.ajax({
      url: "/payment",
      type: "post",
      dataType: "json",
      data: {
      	customer_name: customerName,
      	products: orderProducts
      },
      // headers: {"X-CSRF-TOKEN": $token},
      success: function(total) {
        const fixTotal = (Math.round(total * 100) / 100).toFixed(2);
        showPaymentModal(fixTotal);
      },
      error: function(response) {
      }
    });
	}

	function showPaymentModal(total) {
		var $paymentModalContainer = $(".payment-modal-container");
		var $totalDue = $(".total-due");

		$paymentModalContainer.removeClass('hidden');
		$totalDue.text(total)
	}

	function createOrder(productArray, customerName, immediateOrder) {
		$.ajax({
      url: "/orders",
      type: "post",
      dataType: "json",
      data: {
      	customer_name: customerName,
      	products: productArray,
      	immediate_order: immediateOrder
      },
      // headers: {"X-CSRF-TOKEN": $token},
      success: function(response) {
    		window.location.href = window.location.href
      },
      error: function(response) {
        console.log(response);
      }
    });
	}


	function saveTaskAssignments() {
		$.ajax({
      url: "/assign_main_tasks",
      type: "post",
      dataType: "json",
      data: {
        selected_main_tasks: selectedTasks,
        selected_students: selectedStudents
      },
      // headers: {"X-CSRF-TOKEN": $token},
      success: function(response) {
      },
      error: function(response) {
        console.log(response);
      }
    });
    window.location.reload(true);
	}
})

